<template>
    <SearchboxMainPropel
        id="sb_searchbox"
        :blok="blok.searchbox[0]"
        :loading="loading"
        @submit-storyblok="submitSearch"
        @toggle-loading="toggleLoading"
    />
</template>

<script setup lang="ts">
defineProps({ blok: Object });
const config = useRuntimeConfig();
const loading = ref(false);

function addQueryParamsToUrl(url, params) {
    const urlObj = new URL(url);
    Object.keys(params).forEach((key) => urlObj.searchParams.append(key, params[key]));
    return urlObj.toString();
}
const toggleLoading = () => {
    loading.value = true;

    // Cancel loading after 5 seconds or if user navigates back
    setTimeout(() => {
        loading.value = false;
    }, 5000);
};
const submitSearch = async (body) => {
    const searchUrl = '/booking-quote/' + body.category + '-results';
    navigateTo(addQueryParamsToUrl(config.public.BOOKING_URL + searchUrl, body), { external: true });
};
</script>

<style lang="postcss"></style>
