<template>
    <form id="sbLoungeForm" class="form" @submit.prevent="submitForm()">
        <SearchboxComponentsSimpleSelect
            name="airport"
            :title="destinationTitle"
            :tooltip="destinationTooltip"
            :placeholder="destinationPlaceholder"
            :options="airportList"
            :value="formData.destination.value"
            :validation="v$.destination?.code"
            @update:value="(value: any) => updateDestination(value)"
        />
        <div class="input-group-container flex !p-0">
            <SearchboxComponentsSimpleDate
                name="departureDate"
                :min-date="new Date()"
                label="Departure Date"
                :allow-single-range="true"
                :value="new Date(formData.dates?.start?.date)"
                :validation="v$.dates?.start?.date"
                @update:value="(value: any) => updateDates(value)"
            />
            <SearchboxComponentsSimpleSelect
                name="entryTime"
                title="Lounge Entry Time"
                :options="entryTimeList"
                :allow-single-range="true"
                :value="formData.dates?.start?.timeRange?.value ?? '12:00 - 13:00'"
                :validation="v$.dates?.start?.timeRange?.value"
                append-icon="timer-2-line"
                @update:value="(value: any) => updateTime(value)"
            />
            <div
                v-if="v$.dates?.start?.timeRange && v$.dates?.start?.timeRange.$errors.length"
                class="input-group-container error-container"
            >
                <div class="error-tooltip">
                    {{ v$.dates?.start?.timeRange.$errors[0].$message }}
                </div>
            </div>
        </div>
        <div class="input-group-container flex !p-0">
            <SearchboxComponentsSimpleSelect
                name="adult"
                title="Adults (12+)"
                label="Adults (12+)"
                :options="[
                    { label: '1', value: '1', disabled: false },
                    { label: '2', value: '2', disabled: false },
                    { label: '3', value: '3', disabled: false },
                    { label: '4', value: '4', disabled: false },
                    { label: '5', value: '5', disabled: false },
                    { label: '6', value: '6', disabled: false },
                ]"
                :value="formData.guests?.adults || 1"
                class="flex-1"
                @update:value="(value: any) => updateGuests(value, 'adults')"
            />
            <SearchboxComponentsSimpleSelect
                name="children"
                title="Children (1-12)"
                label="Children (1-12)"
                :options="[
                    { label: '0', value: '0', disabled: false },
                    { label: '1', value: '1', disabled: false },
                    { label: '2', value: '2', disabled: false },
                    { label: '3', value: '3', disabled: false },
                    { label: '4', value: '4', disabled: false },
                    { label: '5', value: '5', disabled: false },
                    { label: '6', value: '6', disabled: false },
                ]"
                :value="formData.guests?.children || 0"
                class="flex-1"
                @update:value="(value: any) => updateGuests(value, 'children')"
            />
            <SearchboxComponentsSimpleSelect
                name="infants"
                title="Infants (0-1)"
                label="Infants (0-1)"
                :options="[
                    { label: '0', value: '0', disabled: false },
                    { label: '1', value: '1', disabled: false },
                    { label: '2', value: '2', disabled: false },
                ]"
                :value="formData.guests?.infants || 0"
                class="flex-1"
                @update:value="(value: any) => updateGuests(value, 'infants')"
            />
        </div>
        <div class="input-group-container flex">
            <div class="flex-1"></div>
            <div class="submit flex-1">
                <span class="title">Submit</span>
                <button type="submit">
                    <template v-if="!loading">Search <span class="fullname">Packages</span></template>
                    <NuxtIcon v-show="loading" name="ri:refresh-line" class="animate-spin loading-icon" />
                </button>
            </div>
        </div>
    </form>
</template>
<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useSearchStore } from '../../../../../apps/_base/store/search';
import { formatDate } from '~ui/utils/helpers';
import { getDateDaysAhead } from '~ui/utils/helpers';

import type { ISearchboxTab } from '../../../../../packages/ui/types';
import type { IAirportLounge } from '~/types/search';

import {
    isMigrationActive,
    checkMigrationVariant,
    inMigratedVariant,
    redirectToLoungePropel,
} from '~/mixins/traffic-splitter';

const {
    loading,
    airportList,
    defaultLocation,
    disablePropel = false,
} = defineProps<{
    tabs: ISearchboxTab;
    airportList: [any];
    defaultLocation: string;
    loading: boolean;
    disablePropel?: boolean;
}>();
const emit = defineEmits<{
    (e: 'submitSearch', query: IAirportLounge): void;
    (e: 'toggleLoading'): void;
}>();

// Constant variable for access to search store
const searchStore: any = useSearchStore();

const destinationTitle = computed(() => {
    return `${searchStore.isCruiseport ? 'Cruise Port' : 'Airport'}`;
});

const destinationTooltip = computed(() => {
    return `Which ${searchStore.isCruiseport ? 'harbour' : 'airport'} are you departing from?`;
});

const destinationPlaceholder = computed(() => {
    return `Enter ${searchStore.isCruiseport ? 'Cruise Port' : 'Airport'}`;
});

const entryTimeList = [
    { label: '00:00 - 01:00', value: '00:00 - 01:00' },
    { label: '01:00 - 02:00', value: '01:00 - 02:00' },
    { label: '02:00 - 03:00', value: '02:00 - 03:00' },
    { label: '03:00 - 04:00', value: '03:00 - 04:00' },
    { label: '04:00 - 05:00', value: '04:00 - 05:00' },
    { label: '05:00 - 06:00', value: '05:00 - 06:00' },
    { label: '06:00 - 07:00', value: '06:00 - 07:00' },
    { label: '07:00 - 08:00', value: '07:00 - 08:00' },
    { label: '08:00 - 09:00', value: '08:00 - 09:00' },
    { label: '09:00 - 10:00', value: '09:00 - 10:00' },
    { label: '10:00 - 11:00', value: '10:00 - 11:00' },
    { label: '11:00 - 12:00', value: '11:00 - 12:00' },
    { label: '12:00 - 13:00', value: '12:00 - 13:00' },
    { label: '13:00 - 14:00', value: '13:00 - 14:00' },
    { label: '14:00 - 15:00', value: '14:00 - 15:00' },
    { label: '15:00 - 16:00', value: '15:00 - 16:00' },
    { label: '16:00 - 17:00', value: '16:00 - 17:00' },
    { label: '17:00 - 18:00', value: '17:00 - 18:00' },
    { label: '18:00 - 19:00', value: '18:00 - 19:00' },
    { label: '19:00 - 20:00', value: '19:00 - 20:00' },
    { label: '20:00 - 21:00', value: '20:00 - 21:00' },
    { label: '21:00 - 22:00', value: '21:00 - 22:00' },
    { label: '22:00 - 23:00', value: '22:00 - 23:00' },
    { label: '23:00 - 00:00', value: '23:00 - 00:00' },
];

const formData: any = ref({
    destination: getDefaultAirport(),
    dates: setDates(),
    guests: {
        adults: searchStore.guests?.adults || '1',
        children: searchStore.guests?.children || '0',
        infants: searchStore.guests?.infants || '0',
    },
    promo: '',
});

const updateDestination = (val: any) => {
    formData.value.destination = val;
    searchStore.update({ destination: val });
    if (isMigrationActive) {
        checkMigrationVariant(searchStore.destination?.code, searchStore.dates.end?.date, '_airportlounge');
    }
};

const updateDates = (val: any) => {
    formData.value.dates.start.date = val;
    searchStore.update({
        dates: formData.value.dates,
    });
    if (isMigrationActive) {
        checkMigrationVariant(searchStore.destination?.code, searchStore.dates.end?.date, '_airportlounge');
    }
};

const updateTime = (val: any) => {
    formData.value.dates.start.timeRange = val;
    formData.value.dates.start.time = val.value.split(' - ')[0];

    searchStore.update({ dates: formData.value.dates });
};

const updateGuests = (val: any, type: string) => {
    formData.value.guests[type] = val.value;

    searchStore.update({
        guests: {
            ...searchStore.guests,
            ...formData.value.guests,
        },
    });
};

onMounted(() => {
    if (isMigrationActive) {
        checkMigrationVariant(searchStore.destination?.code, searchStore.dates.end?.date, '_airportlounge');
    }
});

watch(
    () => formData.value,
    async () => {
        if (isMigrationActive) {
            await checkMigrationVariant(
                formData.value.destination?.code,
                formData.value.dates?.end?.date,
                '_airportlounge'
            );
        }
    },
    { deep: true }
);

function getDefaultAirport() {
    if (defaultLocation) {
        return airportList.find((airport) => airport.code === defaultLocation);
    }

    if (searchStore.destination?.code) {
        return searchStore.destination;
    }

    return { code: '', label: 'Select Airport', value: 0, disabled: false };
}

function getTimeRangeFromTime(time: string) {
    const range = `${time} - ${nextTimeSlot(time, 60)}`;
    return {
        value: range,
        label: range,
    };
}

function setDates() {
    const startTimeRange = searchStore.dates?.start?.time
        ? getTimeRangeFromTime(searchStore.dates.start.time)
        : { value: '12:00 - 13:00', label: '12:00 - 13:00' };

    const startDate = {
        date: searchStore.dates?.start?.date || getDateDaysAhead(new Date(), 1),
        time: searchStore.dates?.start?.time || '12:00',
        timeRange: startTimeRange,
    };
    const endDate = {
        date: searchStore.dates?.end?.date || getDateDaysAhead(new Date(), 8),
        time: searchStore.dates?.end?.time || '12:00',
    };

    const dates = {
        start: startDate,
        end: endDate,
    };

    searchStore.update({ dates: dates });
    return dates;
}

const validate24hour = () => {
    const startDate = new Date(formData.value.dates.start.date);
    const startTime = formData.value.dates.start.timeRange.value.split(' - ')[0].split(':');
    startDate.setHours(startTime[0]);
    startDate.setMinutes(startTime[1]);

    const currentTime = new Date();
    const twentyFourHoursFromNow = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);
    return startDate > twentyFourHoursFromNow;
};

const rules = computed(() => {
    return {
        destination: {
            code: {
                required: helpers.withMessage('Please enter destination', required),
                minLength: minLength(3),
            },
        },
        dates: {
            start: {
                date: {
                    required: helpers.withMessage('Select Date', required),
                    validate24hour: validate24hour,
                },
                timeRange: {
                    value: {
                        required: helpers.withMessage('Select Time Range', required),
                        validate24hour: helpers.withMessage(
                            'You must select a date and time 24 hours after the current time',
                            validate24hour
                        ),
                    },
                },
            },
        },
    };
});

const v$: any = useVuelidate(rules, formData);

const submitForm = () => {
    v$.value.$validate();

    if (!v$.value.$error) {
        emit('toggleLoading');

        searchStore.update({
            destination: formData.value.destination,
            dates: formData.value.dates,
            guests: {
                ...searchStore.guests,
                ...formData.value.guests,
            },
        });

        if (!disablePropel && isMigrationActive && inMigratedVariant.value) {
            redirectToLoungePropel();
            return;
        }

        const query: IAirportLounge = {
            category: 'airportlounge',
            airportA: Number(formData.value.destination.value),
            airportA_code: formData.value.destination.code,
            dateA: formatDate(formData.value.dates.start.date, 'yyyy-mm-dd'),
            dateB: formatDate(formData.value.dates.start.date, 'yyyy-mm-dd'),
            _dateA: formatDate(formData.value.dates.start.date, 'day, dd/mm/yy'),
            _dateB: formatDate(formData.value.dates.start.date, 'day, dd/mm/yy'),
            timeA: formData.value.dates.start.timeRange?.value?.split(' - ')[0],
            timeB: formData.value.dates.start.timeRange?.value?.split(' - ')[1],
            adults: formData.value.guests?.adults,
            children: formData.value.guests?.children,
            infants: formData.value.guests?.infants,
            passengers: 1,
            promo: '',
            agent: 'Lounges',
            params: [],
            terminal: '',
            quoteID: '',
        };

        emit('submitSearch', query);
    }
};
</script>
