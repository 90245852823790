<template>
    <section class="container searchbox-container mx-auto" :class="blok.searchboxStyle">
        <!-- TABS -->
        <div v-if="blok && blok.Tabs" class="tabs">
            <template v-for="tab in blok.Tabs" :key="tab._uid">
                <a
                    href="#"
                    class="tab"
                    :class="{ [tab?.Type]: true, active: tab?.Type === activeTabState }"
                    @click.prevent="setSelectedTab(tab)"
                >
                    <div class="title">
                        <div class="icons">
                            <img
                                v-if="tab?.Type === 'Parking'"
                                src="~/assets/images/icon-parking.webp"
                                alt="airport-parking-icon"
                                loading="eager"
                                densities="x1"
                            />
                            <img
                                v-if="tab?.Type === 'HotelWithParking'"
                                src="~/assets/images/icon-hotel.webp"
                                alt="airport-parking-with-hotel-icon"
                                loading="eager"
                                densities="x1"
                            />
                            <img
                                v-if="tab?.Type === 'Lounge'"
                                src="~/assets/images/icon-lounge.webp"
                                alt="airport-lounge-icon"
                                loading="eager"
                                densities="x1"
                            />
                        </div>
                        <span :class="tab.MobileTitle ? 'hidden md:block' : ''">{{ parkingType(tab.Title) }}</span>
                        <span v-if="tab.MobileTitle" class="md:hidden">{{ parkingType(tab.MobileTitle) }}</span>
                    </div>
                    <div class="subtitle">{{ parkingType(tab.Subtitle) }}</div>
                </a>
            </template>
        </div>

        <!-- FORMS -->
        <div class="forms">
            <template v-for="tab in blok.Tabs" :key="tab._uid">
                <component
                    :is="FormComponents[tab?.Type]"
                    v-if="activeTabState == tab?.Type"
                    :tabs="tab"
                    :loading="loading"
                    :airport-list="airportsList"
                    :default-location="blok.SPSDefault"
                    :default-promo="blok.ShowPromo ? blok.Promo : ''"
                    :show-promo="route.query.promo || blok.ShowPromo"
                    @submit-search="submitForm"
                    @toggle-loading="toggleLoading"
                />
            </template>
        </div>
    </section>
</template>

<script setup lang="ts">
import HotelWithParkingComponent from './Form/HotelWithParking.vue';
import ParkingComponent from './Form/Parking.vue';
import LoungeComponent from './Form/Lounge.vue';
import { useSearchStore } from '../../../../apps/_base/store/search';

import type { ISearchTrip } from '~ui/types/components/Searchbox';
import type { IAirportEntity } from '~ui/types';

const { blok, loading } = defineProps<{ blok: any; loading: boolean }>();

const emit = defineEmits<{
    (e: 'submitStoryblok', body: any): void;
    (e: 'toggleLoading'): void;
}>();

const config: any = useRuntimeConfig();
const route: any = useRoute();
const searchStore: any = useSearchStore();
const activeTabState: any = ref(blok.DefaultTab);
watch(searchStore, (store) => {
    if (activeTabState.value) activeTabState.value = store.activeTab;
});

const FormComponents: any = {
    HotelWithParking: HotelWithParkingComponent,
    Parking: ParkingComponent,
    Lounge: LoungeComponent,
};

const submitForm = (body: ISearchTrip) => {
    emit('submitStoryblok', body);
};
const toggleLoading = () => {
    emit('toggleLoading');
};

if (new Date(searchStore.dates.start.date) < new Date()) {
    const storeDate = new Date(searchStore.dates.start.date);
    const storeTime = searchStore.dates.start.time.split(':');
    storeDate.setHours(storeTime[0] || 0, storeTime[1] || 0);
    if (storeDate <= new Date()) {
        searchStore.update({ dates: { start: '', end: '' } });
    }
}

searchStore.updateIsCruisePort(blok.DefaultCruiseport);
if (!searchStore.activeTab && blok.DefaultTab !== 'None') {
    activeTabState.value = blok.DefaultTab;
    searchStore.updateActiveTab(blok.DefaultTab);
}

onMounted(() => {
    if (import.meta.client) {
        addEventListener('hashchange', () => {
            if (location.hash) {
                switchTabFromHash();
            }
        });
        if (location.hash) {
            switchTabFromHash();
        }
    }

    if (searchStore.activeTab) {
        activeTabState.value = searchStore.activeTab;
    }
});

function setSelectedTab(tab: any) {
    searchStore.updateActiveTab(tab?.Type);
}

function parkingType(text: string) {
    return searchStore.isCruiseport ? text.replace('airport', 'cruise port').replace('Airport', 'Cruise Port') : text;
}

function switchTabFromHash() {
    if (location.hash === '#search-parking') {
        setSelectedTab({ Type: 'Parking' });
    }
    if (location.hash === '#search-hotel-parking') {
        setSelectedTab({ Type: 'HotelWithParking' });
    }
    if (location.hash === '#search-lounges') {
        setSelectedTab({ Type: 'Lounge' });
    }
    if (location.hash.includes('#search')) {
        const noHashURL = window.location.href.replace(/#.*$/, '');
        window.history.replaceState('', document.title, noHashURL);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
    }
}

const airportsList = [
    { label: 'Select Airport', code: '', value: 0, disabled: true },
    { label: 'Aberdeen', code: 'ABZ', value: 18, disabled: false },
    { label: 'Belfast City', code: 'BHD', value: 33, disabled: false },
    { label: 'Belfast International', code: 'BFS', value: 11, disabled: false },
    { label: 'Birmingham', code: 'BHX', value: 12, disabled: false },
    { label: 'Bournemouth', code: 'BOH', value: 30, disabled: false },
    { label: 'Bristol', code: 'BRS', value: 3, disabled: false },
    { label: 'Cardiff', code: 'CWL', value: 13, disabled: false },
    { label: 'Doncaster', code: 'DSA', value: 21, disabled: false },
    { label: 'Dover Port', code: 'DOV', value: 24, disabled: false },
    { label: 'East Midlands', code: 'EMA', value: 4, disabled: false },
    { label: 'Edinburgh', code: 'EDI', value: 5, disabled: false },
    { label: 'Exeter', code: 'EXT', value: 19, disabled: false },
    {
        label: 'Gatwick',
        code: 'LGW',
        value: 16,
        disabled: false,
        terminals: [
            { value: 95, label: 'North Terminal' },
            { value: 96, label: 'South Terminal' },
            { value: '', label: "Don't know" },
        ],
    },
    { label: 'Glasgow', code: 'GLA', value: 6, disabled: false },
    {
        label: 'Heathrow',
        code: 'LHR',
        value: 7,
        disabled: false,
        terminals: [
            { value: 97, label: 'Terminal 1' },
            { value: 98, label: 'Terminal 2' },
            { value: 99, label: 'Terminal 3' },
            { value: 100, label: 'Terminal 4' },
            { value: 101, label: 'Terminal 5' },
            { value: '', label: "Don't know" },
        ],
    },
    { label: 'Humberside', code: 'HUY', value: 20, disabled: false },
    // { label: 'Inverness', code: 'INV', value: 26, disabled: false },
    { label: 'Leeds Bradford', code: 'LBA', value: 14, disabled: false },
    { label: 'Liverpool', code: 'LPL', value: 8, disabled: false },
    { label: 'London City', code: 'LCY', value: 23, disabled: false },
    { label: 'Luton', code: 'LTN', value: 2, disabled: false },
    {
        label: 'Manchester',
        code: 'MAN',
        value: 1,
        disabled: false,
        terminals: [
            { value: 67, label: 'Terminal 1' },
            { value: 68, label: 'Terminal 2' },
            { value: 69, label: 'Terminal 3' },
            { value: '', label: "Don't know" },
        ],
    },
    { label: 'Newcastle', code: 'NCL', value: 15, disabled: false },
    { label: 'Norwich', code: 'NWI', value: 27, disabled: false },
    { label: 'Prestwick', code: 'PIK', value: 9, disabled: false },
    { label: 'Southampton', code: 'SOU', value: 31, disabled: false },
    { label: 'Southampton Port', code: 'GBSOU', value: 22, disabled: false },
    { label: 'Southend', code: 'SEN', value: 32, disabled: false },
    { label: 'Stansted', code: 'STN', value: 10, disabled: false },
    { label: 'Teesside International', code: 'MME', value: 28, disabled: false },
];

// Added for Migration Testing
if (config.public.STORYBLOK_VERSION === 'draft') {
    airportsList.push({ label: 'Test Airport', code: 'PTE', value: 99, disabled: false });
}

const airport: IAirportEntity = inject('airport', {} as IAirportEntity);
if (blok.SPSDefault) {
    searchStore.update({
        destination: {
            code: blok.SPSDefault,
            label: route.params.slug?.[1] || airport?.name || '',
            value: airportsList.find((airport) => airport.code === blok.SPSDefault)?.value ?? '',
            disabled: false,
        },
    });
}
</script>

<style lang="postcss" scoped>
.searchbox-container {
    @apply flex flex-col lg:flex-row bg-white mx-auto md:max-w-[670px] shadow-2xl;

    &.horizontal {
        @apply flex-col max-w-[1120px];
        .tabs {
            @apply flex-row;
            .tab {
                @apply max-w-none lg:min-h-[100px];
            }
        }

        :deep(.form) {
            @apply flex-row flex-wrap;

            .error-container {
                @apply hidden;
            }
            &:has(.error-tooltip) {
                @apply pt-8;
                .error-container {
                    @apply flex;
                }

                .airport-terminal {
                    @apply self-baseline;
                }
            }

            &#sbParkingForm {
                .airport-terminal {
                    @apply flex-[100%];
                }
                .dates-container {
                    @apply max-md:flex-[100%] max-lg:flex-[50%];
                }
                & > *:not(:has(.submit)) {
                    @apply lg:flex-[33%];
                }
                .extras {
                    @apply w-full lg:flex-[100%];
                }
                .submit {
                    @apply ml-0;
                }
                .input-group-container:has(.submit) {
                    &:has([name='promocode']) {
                        .submit {
                            @apply ml-2;
                        }
                        .input-group:has([name='promocode']) {
                            @apply mr-2;
                        }
                    }
                }
            }

            &#sbHotelForm {
                & > * {
                    @apply lg:flex-[33%];
                }
                .dates-container {
                    @apply max-md:flex-[100%] flex-[33%];
                }
                .extras {
                    @apply lg:flex-[100%];
                }
                .submit {
                    @apply justify-end;
                }
                .input-group-wrapper:has([name='roomType']) {
                    @apply max-md:flex-[100%];
                }
            }

            &#sbLoungeForm {
                @apply w-full;
                > div {
                    @apply basis-full md:basis-[33.33%];
                    &.extras {
                        @apply basis-full;
                    }
                }

                > div:first-child:nth-last-child(4),
                > div:first-child:nth-last-child(4) ~ div {
                    @apply md:basis-[50%] lg:basis-[25%];
                    &.airport-terminal {
                        @apply lg:basis-[30%];
                    }
                    &:has(.dates-container) {
                        @apply lg:basis-[30%];
                    }
                    &:has(.guests) {
                        @apply lg:basis-[20%];
                    }
                    &:has(.submit) {
                        @apply lg:basis-[20%] max-h-20;
                    }
                }

                &:has(.extras) {
                    > div:first-child:nth-last-child(5),
                    > div:first-child:nth-last-child(5) ~ div {
                        @apply md:basis-[25%];

                        &.extras {
                            @apply basis-full;
                        }
                    }
                }

                &:has(.guests):has([name='promocode']) {
                    .airport-terminal,
                    .input-group-wrapper {
                        @apply max-md:basis-full max-lg:flex-[50%];
                    }
                    .input-group-container:has(.guests) {
                        @apply max-lg:flex-[50%];
                    }
                    .input-group-container:has([name='promocode']) {
                        @apply flex-[50%];
                    }
                }

                .input-group-wrapper {
                    &:has(#form-input-entryTime) {
                        @apply max-md:mb-0;
                    }

                    &:has(.dates-container) {
                        @apply flex-wrap;
                        & > div:not(.error-container) {
                            @apply flex-[50%];
                        }
                        .error-container {
                            @apply flex-[100%];
                            &:has(.error-tooltip) {
                                @apply max-md:-mt-2;
                            }
                        }
                    }
                }
                .input-group-container {
                    &:has(.submit) {
                        @apply md:flex-[50%] items-end;
                    }
                }
            }
        }

        :deep(.extras) {
            .content-item {
                @apply lg:max-w-[25%] text-xs justify-start md:justify-center;

                &:only-child,
                &:nth-child(2n + 1):last-child {
                    @apply md:max-w-full;
                }

                &:first-child:nth-last-child(2),
                &:first-child:nth-last-child(2) ~ .content-item {
                    @apply md:max-w-[50%];
                }

                &:first-child:nth-last-child(3),
                &:first-child:nth-last-child(3) ~ .content-item {
                    @apply md:max-w-[33%];
                }

                span {
                    @apply flex-none;
                }
            }
        }

        #sbHotelForm {
            @apply py-4;
        }
    }
}

.tabs {
    @apply flex md:flex-col bg-white md:bg-primary;

    .tab {
        @apply relative flex-1 flex flex-col justify-center items-center bg-primary text-white lg:text-left text-center p-4 lg:max-w-[300px] lg:min-h-[140px] transition-all duration-300 ease-in-out;

        .icons {
            @apply text-center mb-2 md:mb-0;
            span {
                @apply hidden md:inline-block;
            }
            [data-icon] {
                @apply mx-1;
            }
            img {
                @apply max-w-10 max-h-10 lg:pr-2;
            }
        }

        .title {
            @apply flex flex-col md:flex-row justify-center items-center text-sm md:text-base font-bold md:leading-6 uppercase;
        }

        .subtitle {
            @apply hidden;
        }

        .icons {
            @apply md:mr-2 text-lg leading-6 whitespace-nowrap;
        }

        span {
            @apply z-[10];
        }

        &.active,
        &:hover {
            @apply flex bg-secondary;
        }
    }

    .tab.active .title,
    .tab.active .subtitle,
    .tab.active + .tab .title,
    .tab.active + .tab .subtitle,
    .tab:first-child .title,
    .tab:first-child .subtitle {
        @apply border-transparent;
    }
}

.forms {
    @apply transition-all w-full;
}

.form {
    @apply flex flex-col px-4 md:px-12 py-4 pb-4;

    :deep(&:has(.error-tooltip)) {
        > div:not(:has(.submit)) {
            @apply items-start;
        }
    }

    &:deep(.input-group-container:has(.guests)) {
        .guests {
            @apply p-0;
            .input-icon-prepend {
                @apply absolute z-[1] text-[#a2a5bb] right-2 top-9;
                .iconify {
                    @apply h-4 w-4;
                }
            }
        }
        &:has([name='promocode']) {
            .guests {
                @apply xl:max-w-[190px] xl:min-w-[190px];
            }
        }
    }

    :deep(.input-group-container) {
        @apply relative p-2 w-full bg-white rounded md:bg-transparent;

        select {
            option[disabled] {
                @apply text-[#b0b4bb];
            }
        }

        &:has(#form-input-departureDate-date) {
            @apply pr-0;
        }

        &:has(#form-input-entryTime) {
            @apply pl-0 h-20;
            #form-input-entryTime {
                @apply border-l-0 rounded-tl-none rounded-bl-none;
            }

            .input-group:has(#form-input-entryTime) {
                [data-icon] {
                    @apply pl-0;
                }
                .input-group-title {
                    @apply hidden;
                }
            }
        }

        .input-group-title {
            @apply static w-full text-base font-bold md:whitespace-nowrap pointer-events-none text-[#64687E] whitespace-nowrap;

            &.has-icon {
                @apply left-9;
            }
        }

        &.error-container {
            @apply flex !py-0 md:!py-2 px-2 -mt-6 md:-mt-4;
        }

        .error-tooltip {
            @apply block w-full mt-[5px] px-2 py-2 bg-red-500 text-white text-xs rounded;
            &.custom-date {
                @apply inline;
                white-space: break-spaces;
                @media screen and (min-width: 767px) {
                    white-space: nowrap;
                }
            }
        }

        .input-group {
            @apply relative;

            &:has(#form-input-airport),
            &:has(#form-input-roomType) {
                [data-icon] {
                    @apply hidden;
                }
                select {
                    @apply px-2;
                }

                .input-icon-append {
                    @apply right-0 left-auto;
                }
            }

            .tooltip {
                @apply hidden;
            }

            .input-icon-prepend {
                @apply hidden;
            }

            .input-icon-append {
                @apply absolute top-[25px] left-[1px] max-w-[38px] max-h-[38px] p-[11px] flex items-center justify-center rounded-r-sm bg-transparent text-[#A2A5BB] pointer-events-none;
                &:not(.hide-desktop) + input {
                    @apply pl-8;
                }

                &[data-icon='sort-down'] {
                    @apply pt-2 pb-[14px] px-3.5;
                }
            }

            input,
            select {
                @apply h-10 w-full md:pt-4 md:pt-0 pl-2 pr-2 bg-white border border-gray-400 rounded text-sm text-gray-600 font-normal hover:border-primary outline-none text-ellipsis;

                &::placeholder {
                    @apply font-thin;
                }

                &[readonly] {
                    @apply cursor-pointer;
                }
            }

            select {
                @apply cursor-pointer hover:border-primary pl-8;
            }

            &.error {
                input,
                select {
                    @apply border-2 !border-red-500;
                }
            }

            .dropdown-list,
            .dropdown-select {
                @apply max-w-full min-w-full md:min-w-0 md:max-w-none absolute top-11 mt-2 md:mt-0 border border-gray-400 rounded bg-white shadow-md md:whitespace-nowrap z-[26];
            }

            .dropdown-list {
                &-item {
                    @apply flex py-4 px-4 border-b border-gray-300 cursor-pointer;
                    &.active {
                        @apply bg-gray-500;
                    }
                    &:hover {
                        @apply bg-gray-200;
                    }
                }
            }

            .dropdown-select {
                @apply py-2;
                &-item {
                    @apply flex py-2 px-4;
                    &-selectors {
                        @apply flex pl-4;
                        .button {
                            @apply mx-2 bg-none border-0 cursor-pointer text-primary text-lg;
                            &:hover {
                                @apply text-primary-350;
                            }
                            &.disabled {
                                @apply text-gray-500;
                            }
                        }
                        .value {
                            @apply p-[1px] min-w-4 text-center;
                        }
                    }
                }
                .dropdown-confirm {
                    @apply px-4;
                    button {
                        @apply h-10 w-full px-8 bg-secondary hover:bg-secondary md:text-lg font-semibold text-white md:uppercase rounded transition-all duration-300;
                    }
                }
            }
        }

        select[name='airport'] {
            &.non-value-selected {
                @apply text-gray-500;
                option {
                    @apply text-[#4b5563];
                }
            }
        }
    }

    :deep(.submit) {
        @apply flex flex-col bg-transparent min-w-[150px];
        span {
            @apply inline-block text-sm mb-1;
        }
        button {
            @apply flex items-center justify-center;
            @apply h-10 px-8 bg-primary hover:bg-secondary md:text-lg font-semibold text-white md:uppercase rounded transition-all duration-300;
            span {
                @apply inline-block text-lg;
            }
        }
    }

    :deep(.airport-terminal) {
        @apply flex px-2;

        &:has(.input-group-container + .input-group-container) {
            select[name='terminal'] {
                @apply px-2 border-l-0 rounded-tl-none rounded-bl-none;
            }

            select[name='airport'] {
                @apply border-r-0 rounded-tr-none rounded-br-none px-2;
                &.non-value-selected {
                    @apply text-gray-500;
                }
            }
        }

        .input-group-container {
            @apply flex items-end px-0;
            &:has([name='terminal']) {
                @apply self-stretch;
                .input-group {
                    .input-icon-append {
                        @apply top-0 right-0 left-auto;
                    }
                }
            }

            .input-group {
                @apply w-full;
                .input-icon-append {
                    @apply right-0 left-auto;
                }
            }
        }
        .input-group-container {
            @apply px-0;
            select {
                @apply px-2;
            }
        }

        .input-group {
            [data-icon] {
                @apply hidden;
            }
        }
    }

    :deep(.input-group-wrapper) {
        &:has(#form-input-entryTime) {
            @apply flex;
            .input-group-container:has(#form-input-entryTime) {
                @apply flex items-end;
                .input-group {
                    @apply w-full relative;
                    [data-icon] {
                        @apply top-0;
                    }
                }
            }
        }
    }

    :deep(.dates-container) {
        @apply w-full;
        .input-group {
            &:has(.time-select-input) {
                @apply flex items-end px-0;
            }
            &:has(.single-date-datepicker-input) {
                @apply px-0 mr-0;
            }
            #form-input-departureDate-date,
            .single-date-datepicker-input {
                @apply border-r-0 rounded-tr-none rounded-br-none;
            }
            .time-select-input {
                @apply border-l-0 rounded-tl-none rounded-bl-none;
            }
            .time-label {
                @apply hidden;
            }
        }
    }

    :deep(.extras) {
        .content-item {
            @apply md:basis-[50%];

            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ .content-item {
                @apply md:basis-[50%];
            }

            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ .content-item {
                @apply md:max-w-full md:basis-[100%];
            }
        }
    }
}

:deep(#sbParkingForm) {
    .input-group-container:has([name='promocode']) {
        .submit {
            @apply justify-end ml-4;
        }
    }
}

:deep(#sbLoungeForm) {
    .submit {
        @apply w-full;
    }

    .input-group-container:has(.guests) {
        @apply max-md:flex-col;

        .guests {
            @apply max-md:px-0;

            .dropdown-select {
                @apply max-md:fixed max-md:top-auto max-md:bottom-0 max-md:inset-x-0;
            }
            .back-drop {
                @apply items-center bg-[rgba(40,44,74,0.25)] flex justify-center fixed z-10 inset-0;
            }
        }
    }

    .input-group:has(select[name='entryTime']) {
        .input-icon-append {
            @apply top-0;
        }
    }

    .input-group-wrapper {
        &:has(.dates-container) {
            @apply flex-wrap;
            & > div:not(.error-container) {
                @apply flex-[50%];
            }
            .error-container {
                @apply flex-[100%] max-md:mt-0;
                &:has(.error-tooltip) {
                    @apply max-md:-mt-2;
                }
            }
        }
    }
}

:deep(#sbHotelForm) {
    @apply py-2;
    .input-group-container {
        @apply py-1;
    }
}

:deep(.extras) {
    @apply relative flex flex-col md:flex-row bg-[#D7E4EC] text-white flex-wrap gap-x-3 gap-y-2 m-2 rounded-lg shadow-md;
    .title {
        @apply relative px-4 text-3xl bg-secondary py-4 rounded-t md:rounded-l;
    }
    .content {
        @apply flex flex-row flex-wrap pb-2 md:pb-0 text-xs w-full;

        .content-item {
            @apply flex md:block max-md:px-4 items-center cursor-pointer text-primary font-bold;
            @apply md:flex md:max-w-[50%];

            &:only-child,
            &:nth-child(2n + 1):last-child {
                @apply md:max-w-full;
            }
            span {
                @apply uppercase whitespace-normal max-w-[calc(100%_-_3.5rem)];
            }
            .checkmark {
                @apply h-6 -mb-1 md:mb-[1px] text-secondary mr-2;
            }
            .help {
                @apply text-[#82ACC3] ml-2 h-4;
            }
        }
    }
    .content-item {
        @apply relative flex-[100%] md:flex-[50%] px-2 py-2;
        &:hover {
            .tooltip {
                @apply opacity-100 transition-opacity duration-500 ease-in-out;
            }
        }

        .tooltip {
            @apply font-normal opacity-0 absolute md:min-w-[350px] -left-4 md:-left-12 -right-4 md:right-auto bottom-[calc(100%_+_10px)] bg-white text-gray-800 text-sm rounded-md shadow-2xl p-4 z-10 pointer-events-none;
            &:before {
                @apply content-[''] absolute left-[calc(50%_-_20px)] -bottom-2 border-solid border-t-8 border-x-8 border-b-0 border-transparent border-t-white z-10;
            }
        }
    }
}
</style>
