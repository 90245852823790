<template>
    <form id="sbParkingForm" class="form" @submit.prevent="submitForm()">
        <SearchboxComponentsSimpleSelect
            name="airport"
            :title="destinationTitle"
            :tooltip="destinationTooltip"
            :placeholder="destinationPlaceholder"
            :options="airportList"
            :value="formData.destination.value"
            :validation="v$?.destination?.code"
            @update:value="(value: any) => updateDestination(value)"
        />
        <SearchboxComponentsDateTimePicker
            :package-info="option"
            :label="{ date: 'Drop Off Date', time: 'Time' }"
            name="dropoff"
            :min-date="new Date()"
            :value="formData?.dates?.start"
            :validation="v$?.dates?.start"
            :allow-single-range="true"
            @update:value="(value: any) => updateDates(value, 'start')"
        />
        <SearchboxComponentsDateTimePicker
            :package-info="option"
            :label="{ date: 'Pick Up Date', time: 'Time' }"
            name="pickup"
            :min-date="new Date(formData.dates.start?.date) ?? new Date()"
            :value="formData.dates.end"
            :validation="v$?.dates?.end"
            :allow-single-range="true"
            @update:value="(value: any) => updateDates(value, 'end')"
        />
        <div class="input-group-container flex">
            <SearchboxComponentsSimpleInput
                v-if="showPromo"
                title="Promo Code"
                placeholder="Promo Code"
                name="promocode"
                :value="formData.promo"
                class="flex-1 !p-0"
                @update:value="(value: any) => updatePromo(value)"
            />
            <div v-else class="flex-1"></div>
            <div class="submit flex-1 ml-4">
                <span class="title">Submit</span>
                <button type="submit">
                    <template v-if="!loading">Search <span class="fullname">Parking</span></template>
                    <NuxtIcon v-show="loading" name="ri:refresh-line" class="animate-spin loading-icon" />
                </button>
            </div>
        </div>
        <div v-if="tabs.Extras" class="extras flex">
            <div class="title">EXTRAS</div>
            <div class="content">
                <div class="content-item">
                    <NuxtIcon name="ri:checkbox-fill" class="checkmark" />
                    <span class="flex-1">Cancel For Free</span>
                    <NuxtIcon name="ri:question-line" class="ml-2 h-[14px]" />
                    <div class="tooltip">
                        Cancelling is free and easy with SkyParkSecure. Cancel at least 24 hours before your booking is
                        due to start. We will refund you straight away, but it may take 3–10 working days to appear in
                        your account. Not applicable to NON-FLEX products.
                    </div>
                </div>
                <div class="content-item">
                    <NuxtIcon name="ri:checkbox-fill" class="checkmark" />
                    <span class="flex-1">Free SMS</span>
                    <NuxtIcon name="ri:question-line" class="ml-2 h-[14px]" />
                    <div class="tooltip">
                        Your SMS message will show your name, contact no. and the car park postcode (for satnav), along
                        with booking times and booking confirmation number.
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import type { Validation } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useSearchStore } from '~/../_base/store/search';
import { formatDate } from '~ui/utils/helpers';

// Define local types since external types are not found
interface ISearchboxTab {
    Extras?: boolean;
    [key: string]: any;
}

interface IAirportParking {
    category: string;
    airportA: number;
    airportA_code: string;
    dateA: string;
    dateB: string;
    _dateA: string;
    _dateB: string;
    timeA: string;
    timeB: string;
    promo: string;
    passengers: number;
    agent: string;
    params: any[];
    terminal: string;
    quoteID: string;
    nohotel: number;
    park_type: string;
}

// Import traffic splitter functions directly
import {
    isMigrationActive,
    checkMigrationVariant,
    redirectToParkingPropel,
    inMigratedVariant,
} from '~/mixins/traffic-splitter';

// Define prop and emit types
const {
    loading = false,
    defaultPromo = '',
    defaultLocation = '',
    airportList = [],
    showPromo = false,
    tabs = {},
    disablePropel = false,
} = defineProps<{
    tabs?: ISearchboxTab;
    airportList?: any[];
    defaultPromo?: string;
    defaultLocation?: string;
    showPromo?: boolean;
    loading?: boolean;
    disablePropel?: boolean;
}>();

const emit = defineEmits<{
    (e: 'submitSearch', query: IAirportParking): void;
    (e: 'toggleLoading'): void;
}>();

// Constant variable for access to search store
const searchStore = useSearchStore();
const route: any = useRoute();

// Utility function to get days ahead
const getDateDaysAhead = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

const destinationTitle = computed(() => {
    return `${searchStore.isCruiseport ? 'Cruise Port' : 'Airport'}`;
});

const destinationTooltip = computed(() => {
    return `Which ${searchStore.isCruiseport ? 'harbour' : 'airport'} are you departing from?`;
});

const destinationPlaceholder = computed(() => {
    return `Enter ${searchStore.isCruiseport ? 'Cruise Port' : 'Airport'}`;
});

// Define form data type with more flexible date handling
interface FormData {
    destination: any;
    dates: {
        start: {
            date: Date;
            time: string;
            timeRange?: { value: string; label?: string };
        };
        end: {
            date: Date;
            time: string;
        };
    };
    promo: string;
    guests?: {
        adult?: number;
        children?: number;
        infants?: number;
    };
}

const updateDestination = async (val: any) => {
    formData.value.destination = val;
    searchStore.update({ destination: val });
    if (isMigrationActive()) {
        await checkMigrationVariant(formData.value.destination?.code, formData.value.dates?.end?.date);
    }
};

const updateDates = async (val: any, position: string) => {
    formData.value.dates[position].date = val.date;
    formData.value.dates[position].time = val.time;
    searchStore.update({ dates: formData.value.dates });
    if (isMigrationActive()) {
        await checkMigrationVariant(formData.value.destination?.code, formData.value.dates?.end?.date);
    }
};

const updatePromo = (val: any) => {
    formData.value.promo = val;
    searchStore.updatePromo(val);
};

// Helper function to normalize dates
const normalizeDates = (dates: any) => {
    // If dates is a simple object with start/end strings, convert to expected structure
    if (typeof dates.start === 'string' && typeof dates.end === 'string') {
        return {
            start: {
                date: new Date(dates.start),
                time: '12:00',
                timeRange: { value: '12:00 - 13:00', label: '12:00 - 13:00' },
            },
            end: {
                date: new Date(dates.end),
                time: '12:00',
            },
        };
    }

    // If dates already match the expected structure, return as-is
    return dates;
};

const formData = ref<FormData>({
    destination: getDefaultAirport(),
    dates: searchStore.dates?.start ? normalizeDates(searchStore.dates) : setDefaultDates(),
    promo: route.query.promo || searchStore.promo || defaultPromo || '',
    guests: {
        adult: 1,
        children: 0,
        infants: 0,
    },
});

function setDefaultDates() {
    const defaultStartDate = {
        date: getDateDaysAhead(new Date(), 1),
        time: '12:00',
        timeRange: { value: '12:00 - 13:00', label: '12:00 - 13:00' },
    };

    const defaultEndDate = {
        date: getDateDaysAhead(new Date(), 8),
        time: '12:00',
    };

    const defaultDates = {
        start: defaultStartDate,
        end: defaultEndDate,
    };

    searchStore.update({ dates: defaultDates });
    return defaultDates;
}

function getDefaultAirport() {
    if (defaultLocation) {
        return airportList.find((airport) => airport.code === defaultLocation);
    }

    if (searchStore.destination?.value) {
        return searchStore.destination;
    }

    return { code: '', label: 'Select Airport', value: 0, disabled: false };
}

const option = computed(() => {
    return {
        id: 'APR',
        name: 'At the parkinglot',
        additional: '',
        start_date: {
            title: 'Parking Check-In',
            tooltip: 'Date when your parking begins',
            placeholder: 'Select Date',
        },
        end_date: {
            title: 'Parking Check-Out',
            tooltip: 'Date when your parking ends',
            placeholder: 'Select Date',
        },
    };
});

const beforeCurrentTime = () => {
    const currentTime = new Date();
    const startDate = new Date(formData.value.dates.start.date);
    const startTime = formData.value.dates.start.time.split(':');

    startDate.setHours(parseInt(startTime[0]));
    startDate.setMinutes(parseInt(startTime[1]));

    return startDate > currentTime;
};

const beforeStartDate = () => {
    const startDate = new Date(formData.value.dates.start.date);
    const startTime = formData.value.dates.start.time.split(':');
    startDate.setHours(parseInt(startTime[0]));
    startDate.setMinutes(parseInt(startTime[1]));

    const endDate = new Date(formData.value.dates.end.date);
    const endTime = formData.value.dates.end.time.split(':');
    endDate.setHours(parseInt(endTime[0]));
    endDate.setMinutes(parseInt(endTime[1]));

    return endDate > startDate;
};

const rules = computed(() => {
    return {
        destination: {
            code: {
                required: helpers.withMessage('Please enter destination', required),
                minLength: minLength(3),
            },
        },
        dates: {
            start: {
                date: {
                    required: helpers.withMessage('Select Date', required),
                    beforeCurrentTime: helpers.withMessage(
                        'Drop Off Date cannot be before the current time',
                        beforeCurrentTime
                    ),
                },
                time: { required: helpers.withMessage('Select Time', required) },
            },
            end: {
                date: {
                    required: helpers.withMessage('Select Date', required),
                    beforeStartDate: helpers.withMessage('Drop Off Date must be before Pick Up Date.', beforeStartDate),
                },
                time: { required: helpers.withMessage('Select Time', required) },
            },
        },
    };
});

// Add type safety for validation
const v$ = useVuelidate(rules, formData) as Ref<Validation>;

onMounted(async () => {
    if (isMigrationActive()) {
        await checkMigrationVariant(searchStore.destination?.airport_code, searchStore.dates?.end);
    }
});

watch(
    () => formData.value,
    async () => {
        if (isMigrationActive()) {
            await checkMigrationVariant(formData.value.destination?.code, formData.value.dates?.end?.date);
        }
    },
    { deep: true }
);

const submitForm = async () => {
    v$.value.$validate();

    if (!v$.value.$error) {
        emit('toggleLoading');

        searchStore.update({
            destination: formData.value.destination,
            dates: formData.value.dates,
            guests: {
                ...searchStore.guests,
                ...formData.value.guests,
            },
        });

        const migrationCheck = await checkMigrationVariant(
            formData.value.destination?.code,
            formData.value.dates?.end?.date
        );

        if (!disablePropel && isMigrationActive() && migrationCheck.inMigratedVariant.value) {
            redirectToParkingPropel();
            return;
        }

        const query = {
            category: 'airportparking',
            airportA: Number(formData.value.destination.value),
            airportA_code: formData.value.destination.code,
            dateA: formatDate(formData.value.dates.start.date, 'yyyy-mm-dd'),
            dateB: formatDate(formData.value.dates.end.date, 'yyyy-mm-dd'),
            _dateA: formatDate(formData.value.dates.start.date, 'day, dd/mm/yy'),
            _dateB: formatDate(formData.value.dates.end.date, 'day, dd/mm/yy'),
            timeA: formData.value.dates.start.time,
            timeB: formData.value.dates.end.time,
            promo: formData.value.promo,
            passengers: 1,
            agent: 'ONLINE',
            params: [],
            terminal: '',
            quoteID: '',
            nohotel: 0,
            park_type: 'all',
        };

        emit('submitSearch', query);
    }
};
</script>

<style lang="postcss" scoped>
.extras {
    @apply relative flex flex-col md:flex-row bg-primary text-white flex-wrap gap-x-3 gap-y-2 mx-1 mt-4 rounded shadow-md;
    .title {
        @apply relative px-4 text-3xl bg-secondary py-4 rounded-t md:rounded-l;
        &::after {
            content: '';
            @apply absolute hidden md:block top-0 -right-[15px] bottom-0;
            border-top: 34px solid transparent;
            border-bottom: 34px solid transparent;
            border-left: 15px solid #f6ab2f;
        }
    }
    .content {
        @apply flex flex-col justify-center flex-1 pl-4 pr-4 pb-2 md:pb-0 text-sm;

        .content-item {
            @apply flex items-center py-0.5 cursor-pointer;
            span {
                @apply uppercase;
            }
            .checkmark {
                @apply h-[15px] mb-[1px] text-secondary mr-2;
            }
        }
    }
    .content-item {
        @apply relative;
        &:hover {
            .tooltip {
                @apply opacity-100 transition-opacity duration-500 ease-in-out;
            }
        }

        .tooltip {
            @apply opacity-0 absolute md:min-w-[350px] -left-4 -right-4 md:-left-1/2 md:right-auto bottom-[calc(100%_+_10px)] bg-white text-gray-800 text-sm rounded-md shadow-2xl p-4 z-10 pointer-events-none;
            &:before {
                @apply content-[''] absolute left-[calc(50%_-_20px)] -bottom-2 border-solid border-t-8 border-x-8 border-b-0 border-transparent border-t-white z-10;
            }
        }
    }
}
</style>
