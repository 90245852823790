<template>
    <form id="sbHotelForm" class="form" @submit.prevent="submitForm()">
        <SearchboxComponentsSimpleSelect
            name="airport"
            :title="destinationTitle"
            :tooltip="destinationTooltip"
            :placeholder="destinationPlaceholder"
            :options="airportList"
            :value="formData.destination.value"
            :validation="v$.destination?.code"
            @update:value="(value: any) => updateDestination(value)"
        />
        <SearchboxComponentsSimpleDate
            :min-date="new Date()"
            name="nightOfStay"
            title="Night Of Stay"
            label="Night Of Stay"
            :allow-single-range="true"
            :value="new Date(formData.dates?.start?.date)"
            :validation="v$.dates.start.date"
            @update:value="(value: any) => updateDates(value, 'start')"
        />
        <SearchboxComponentsSimpleDate
            name="parkingUntil"
            :min-date="formData.dates?.start?.date ?? new Date()"
            label="Parking Until"
            :allow-single-range="true"
            :value="new Date(formData.dates?.end?.date)"
            :validation="v$.dates.end.date"
            @update:value="(value: any) => updateDates(value, 'end')"
        />
        <SearchboxComponentsSimpleSelect
            name="roomType"
            title="Room Type"
            label="Room Type"
            placeholder="Select Room Type"
            :options="roomTypeList"
            :value="formData.guests?.rooms.value"
            :validation="v$.guests.rooms"
            @update:value="(value: any) => updateRooms(value)"
        />
        <div class="input-group-container flex">
            <div class="flex-1"></div>
            <div class="submit flex-1">
                <span class="title">Submit</span>
                <button type="submit">
                    <template v-if="!loading">Search <span class="fullname">Packages</span></template>
                    <NuxtIcon v-show="loading" name="ri:refresh-line" class="animate-spin loading-icon" />
                </button>
            </div>
        </div>
    </form>
</template>
<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useSearchStore } from '../../../../../apps/_base/store/search';
import { formatDate, getDaysBetweenDates } from '~ui/utils/helpers';

import type { ISearchboxTab } from '~ui/types';
import type { IAirportHotelParking } from '@/types/search';

const { loading, airportList, defaultLocation } = defineProps<{
    tabs: ISearchboxTab;
    airportList: [any];
    defaultLocation: string;
    loading: boolean;
}>();
const emit = defineEmits<{
    (e: 'submitSearch', query: IAirportHotelParking): void;
    (e: 'toggleLoading'): void;
}>();

// Constant variable for access to search store
const searchStore = useSearchStore();

const destinationTitle = computed(() => {
    return `${searchStore.isCruiseport ? 'Cruise Port' : 'Airport'}`;
});

const destinationTooltip = computed(() => {
    return `Which ${searchStore.isCruiseport ? 'harbour' : 'airport'} are you departing from?`;
});

const destinationPlaceholder = computed(() => {
    return `Enter ${searchStore.isCruiseport ? 'Cruise Port' : 'Airport'}`;
});

const roomTypeList = [
    { label: 'Single', value: '1' },
    { label: 'Double', value: '2' },
    { label: 'Twin', value: '3' },
    { label: 'Triple (3 adults)', value: '4' },
    { label: 'Family of 3', value: '5' },
    { label: 'Family of 4', value: '6' },
    { label: 'Family of 5', value: '7' },
    { label: 'Family of 6', value: '1001' },
    { label: 'Executive Double', value: '1003' },
    { label: 'Honeymoon', value: '1004' },
    { label: 'Family of 7', value: '1005' },
    { label: 'Executive Single', value: '1008' },
    { label: 'Quad (4 adults)', value: '1009' },
    { label: 'Exec.Family 4', value: '1010' },
];

const updateDestination = (val: any) => {
    formData.value.destination = val;

    searchStore.update({
        destination: formData.value.destination,
    });
};

const updateDates = (val: any, position) => {
    formData.value.dates[position].date = val;

    searchStore.update({
        dates: formData.value.dates,
    });
};

const updateRooms = (val: any) => {
    formData.value.guests.rooms = val;

    searchStore.updateRooms(formData.value.guests.rooms);
};

const formData: any = ref({
    destination: getDefaultAirport(),
    // @ts-expect-error - date is not in type
    dates: searchStore.dates?.start?.date ? searchStore.dates : setDefaultDates(),
    guests: {
        rooms: {
            label: searchStore.guests?.rooms?.label || 'Single',
            value: searchStore.guests?.rooms?.value || '1',
        },
    },
    promo: '',
});

function getDefaultAirport() {
    if (defaultLocation) {
        return airportList.find((airport) => airport.code === defaultLocation);
    }

    // @ts-expect-error - code is not in type
    if (searchStore.destination?.code) {
        return searchStore.destination;
    }

    return { code: '', label: 'Select Airport', value: 0, disabled: false };
}

function setDefaultDates() {
    const defaultStartDate = {
        date: getDateDaysAhead(new Date(), 1),
        time: '12:00',
        timeRange: { value: '12:00 - 13:00', label: '12:00 - 13:00' },
    };

    const defaultEndDate = { date: getDateDaysAhead(new Date(), 8), time: '12:00' };

    const defaultDates = {
        start: defaultStartDate,
        end: defaultEndDate,
    };

    searchStore.update({ dates: defaultDates });
    return defaultDates;
}

const validate24hour = (value) => {
    const selectedDate = new Date(formData.value.dates.start.date);
    const twentyFourHoursFrom = new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000);
    return new Date(value) > twentyFourHoursFrom;
};

const rules = computed(() => {
    return {
        destination: {
            code: {
                required: helpers.withMessage('Please enter destination', required),
                minLength: minLength(3),
            },
        },
        dates: {
            start: {
                date: {
                    required: helpers.withMessage('Select Date', required),
                },
            },
            end: {
                date: {
                    required: helpers.withMessage('Select Date', required),
                    validate24hour: helpers.withMessage(
                        'You must select a Parking Until date 24 hours after the night of stay time',
                        validate24hour
                    ),
                },
            },
        },
        guests: {
            rooms: {
                value: {
                    required: helpers.withMessage('Please select rooms type', required),
                },
            },
        },
    };
});

const v$: any = useVuelidate(rules, formData);

const submitForm = () => {
    v$.value.$validate();

    if (!v$.value.$error) {
        emit('toggleLoading');

        searchStore.update({
            destination: formData.value.destination,
            dates: formData.value.dates,
            guests: {
                ...formData.value.guests,
                rooms: formData.value.guests.rooms,
            },
        });

        const query: IAirportHotelParking = {
            category: 'airporthotel',
            airportA: Number(formData.value.destination.value),
            airportA_code: formData.value.destination.code,
            dateA: formatDate(formData.value.dates.start.date, 'yyyy-mm-dd'),
            dateB: formatDate(formData.value.dates.end.date, 'yyyy-mm-dd'),
            _dateA: formatDate(formData.value.dates.start.date, 'day, dd/mm/yy'),
            _dateB: formatDate(formData.value.dates.end.date, 'day, dd/mm/yy'),
            timeA: formData.value.dates.start.time,
            timeB: formData.value.dates.end.time,
            room1: formData.value.guests.rooms.value,
            parking_days: getDaysBetweenDates(formData.value.dates.start.date, formData.value.dates.end.date) + 1,
            promo: '',
            passengers: 1,
            agent: 'ONLINE',
            params: [],
            terminal: '',
            quoteID: '',
        };

        emit('submitSearch', query);
    }
};
</script>
