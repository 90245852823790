<template>
    <div class="input-group-container guests has-value">
        <div v-show="dropdown" class="back-drop"></div>
        <div v-if="prependIcon" class="input-icon-prepend">
            <NuxtIcon :name="`ri:${prependIcon}`" />
        </div>
        <label class="input-group-title">{{ title }}</label>
        <div class="input-group">
            <input
                :id="`form-input-${name}`"
                type="text"
                :name="name"
                :value="inputValue"
                class="tooltip-handle"
                readonly
                @click="toggleDropdown()"
            />
            <div class="tooltip">{{ tooltip }}</div>
            <div v-show="dropdown" ref="target" class="dropdown-select">
                <NumberInput
                    :min-value="1"
                    :max-value="maxAdults"
                    title="Adult 16+"
                    :value="adults"
                    class="flex justify-between"
                    @on-decrease="onDecreaseAdults"
                    @on-increase="onIncreaseAdults"
                />
                <NumberInput
                    :min-value="0"
                    :max-value="maxChildren"
                    title="Child 3 - 15 years old"
                    :value="children"
                    class="flex justify-between"
                    @on-decrease="onDecreaseChildren"
                    @on-increase="onIncreaseChildren"
                />

                <div class="input-group dropdown-confirm">
                    <button type="button" @click="onConfirm">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import NumberInput from '~/components/Searchbox/Components/NumberInput.vue';

const props: any = defineProps({
    name: { type: String, required: true, default: 'Airport' },
    title: { type: String, required: true, default: 'Title' },
    tooltip: { type: String, required: true, default: 'Tooltip' },
    prependIcon: { type: String, required: false, default: '' },
    value: { type: Object, required: false, default: () => ({ adults: 1, children: 0 }) },
});

// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value']);

// Component Ref
const target: any = ref(null);

// Dropdown Status Ref
const dropdown: any = ref(false);

// Selected Values
const adults: any = ref(props.value.adults || 1);
const children: any = ref(props.value.children || 0);
const maxValue = 6;

const maxAdults = computed(() => maxValue - children.value);
const maxChildren = computed(() => maxValue - adults.value);
const inputValue = computed(
    () =>
        `${props.value.adults || 1} Adult${props.value.adults > 1 ? 's' : ''}, ${props.value.children || 0} ${props.value.children > 1 ? 'Children' : 'Child'}`
);
// Outside click event
onClickOutside(target, () => {
    adults.value = props.value.adults;
    children.value = props.value.children;
    dropdown.value = false;
});

function toggleDropdown(bool: any = null) {
    if (bool === null) {
        dropdown.value = !dropdown.value;
    } else {
        dropdown.value = bool;
    }
}

function onConfirm() {
    dropdown.value = false;
    emit('update:value', { adults: adults.value, children: children.value });
}

function onDecreaseAdults() {
    if (adults.value <= 1) {
        return;
    }
    adults.value--;
}

function onIncreaseAdults() {
    if (adults.value >= maxAdults.value) {
        return;
    }
    adults.value++;
}

function onDecreaseChildren() {
    if (children.value <= 0) {
        return;
    }
    children.value--;
}

function onIncreaseChildren() {
    if (children.value >= maxChildren.value) {
        return;
    }
    children.value++;
}
</script>
