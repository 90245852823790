<template>
    <div class="dates-container">
        <VDatePicker
            v-model="dateVal"
            mode="date"
            expanded
            is-required
            color="primary"
            :class="anchor"
            :columns="columnCount"
            :min-page="minPage"
            :min-date="minDate"
            :max-date="maxDate"
            :popover="{ visibility: !calendarShow ? 'click' : 'focus' }"
            :locale="{
                id: localeObj.iso,
                firstDayOfWeek: 1,
                masks: { weekdays: 'WW', input: 'WWW, DD/MM/YYYY' },
            }"
            @popover-will-show="toggleCalendar(true)"
            @popover-will-hide="toggleCalendar(false)"
            @popover-did-hide="toggleCalendar(false)"
        >
            <!-- eslint-disable-next-line vue/no-unused-vars, prettier/prettier -->
            <template #default="{ inputValue, inputEvents, hidePopover }">
                <div class="dates" :class="{ open: calendarShow }" :data-title="datePickerTitle">
                    <div class="close" @click="hidePopover">
                        <NuxtIcon name="ri:close-line" />
                    </div>
                    <div class="input-group-container has-value pr-2">
                        <div class="group-inputs">
                            <div
                                class="input-group"
                                :class="{ error: validation ? validation.$error : false }"
                                :data-tooltip="tooltip"
                            >
                                <label :for="`form-input-${name}-date`" class="input-group-title has-icon">
                                    {{ label }}
                                </label>
                                <div class="input-icon-prepend">
                                    <NuxtIcon name="ri:calendar-2-line" />
                                </div>
                                <div class="input-icon-append">
                                    <NuxtIcon name="ri:calendar-2-line" />
                                </div>
                                <input
                                    :id="`form-input-${name}-date`"
                                    type="text"
                                    :name="`${name}_date`"
                                    class="tooltip-handle"
                                    :data-tooltip="tooltip"
                                    :placeholder="placeholder"
                                    :value="inputValue"
                                    readonly
                                    v-on="inputEvents"
                                />
                                <div v-if="tooltip?.length" class="tooltip">{{ tooltip }}</div>
                                <div v-if="validation?.$errors?.[0]?.$message" class="error-tooltip" :class="name">
                                    {{ validation.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </VDatePicker>
    </div>
</template>

<script setup lang="ts">
import { hydrateOnInteraction } from 'vue';

const VDatePicker = defineAsyncComponent({
    loader: async () => (await import('@angelblanco/v-calendar')).DatePicker,
    delay: 200,
    timeout: 3000,
    hydrate: hydrateOnInteraction(['focus', 'mouseenter']),
});

// Calendar Styles
import '@angelblanco/v-calendar/style.css';

const props: any = defineProps({
    name: { type: String, required: true },
    value: { type: [Date, String], required: false, default: '' },
    label: { type: String, required: false },
    minDate: { type: Date, required: false, default: new Date() },
    maxDate: { type: Date, required: false, default: new Date(new Date().setMonth(new Date().getMonth() + 12)) },
    placeholder: { type: String, required: false, default: 'Select Date' },
    datePickerTitle: { type: String, required: false, default: 'Please select a date' },
    tooltip: { type: String, required: false, default: '' },
    anchor: { type: String, required: false, default: 'left' },
    validation: { type: Object, required: false, default: null },
    allowSingleRange: { type: Boolean, required: false, default: false },
});

// Resolve Datepicker
const calendarShow: any = ref(false);

const isMobile: any = ref(false);
const columnCount = ref(2);

// Constant Variable for access to locale & translations
const { locales, locale } = useI18n();

// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value']);
5;

const minPage: object = {
    month: new Date(new Date().setMonth(new Date().getMonth())).getMonth(),
    year: new Date().getFullYear(),
};

const localeObj: any = Object.values(locales.value).find((item: any) => item.code === locale.value);

// Whenever new date is set, we want to update locale date and emit the data
// to parent where it will set the new minimum date when required.
// We then close dropdown as it is no longer needed.
const dateVal: any = computed({
    get(): any {
        return props.value;
    },
    set(value: any): any {
        emit('update:value', value);
        return value;
    },
});

function toggleCalendar(val: boolean) {
    calendarShow.value = val;
    if (val) {
        document.body.classList.add('overflow-hidden', 'md:overflow-auto', 'min-h-screen');
    } else {
        document.body.classList.remove('overflow-hidden', 'md:overflow-auto', 'min-h-screen');
    }
}

// Change column count from 1 (mobile) to 2 (desktop/tablet)
function onWidthChange() {
    if (768 < window.innerWidth) {
        columnCount.value = props.allowSingleRange ? 1 : 2;
        isMobile.value = false;
    } else {
        columnCount.value = 1;
        isMobile.value = true;
    }
}

if (!import.meta.server) {
    onMounted(() => {
        // Initialize Column Count
        onWidthChange();

        // Watch for column count
        window.addEventListener('resize', onWidthChange);
    });
    onUnmounted(() => window.removeEventListener('resize', onWidthChange));
}
</script>

<style lang="postcss" scoped>
.dates-container {
    @apply relative;

    .bar {
        @apply hidden xl:block opacity-0 absolute top-[4.5rem] z-[1] h-[2px] w-24 bg-primary-500 rounded pointer-events-none transition;
        &.right {
            @apply opacity-100 translate-x-[90%];
        }
        &.left {
            @apply opacity-100 -translate-x-3/4;
        }
    }

    .input-arrow {
        @apply hidden text-gray-500;
    }

    .dates {
        @apply flex justify-center items-center w-full;

        .close {
            @apply hidden;
        }

        & + :deep(.vc-container) {
            @apply fixed md:absolute md:top-12 bottom-0 md:bottom-auto left-0 right-0 z-[999] min-w-full max-w-[100vw] md:min-w-0 rounded border-[#e9daf0] border-b-[20px] flex flex-col;
        }

        /*** Mobile Only ***/
        @media (max-width: theme('screens.md')) {
            &.open {
                @apply fixed top-14 left-0 right-0 bg-white;
                &:before {
                    content: attr(data-title);
                    @apply fixed top-0 left-0 right-0 flex items-center justify-center h-14 py-3 px-4 bg-transparent whitespace-nowrap text-white font-bold border-b border-gray-200 bg-primary-500 text-sm text-center;
                }

                .input-group-title {
                    @apply !left-0 !right-0 text-center !text-xs;
                }

                .input-arrow {
                    @apply block md:hidden relative -top-1;
                }

                .input-group {
                    [data-icon='calendar-days'] {
                        @apply hidden;
                    }
                    input {
                        @apply !px-0 text-center outline-0 !border-none rounded-none;

                        &:focus {
                            @apply !border-b-4 !border-primary-500;
                        }
                    }
                }

                .close {
                    @apply fixed block left-4 top-4 text-white;
                }

                & + :deep(.vc-popover-content-wrapper) {
                    @apply !fixed !top-[5rem] !left-0 !right-0 !bottom-0 !transform bg-white z-[99];

                    .vc-popover-content {
                        @apply !mt-0 rounded-none shadow-none border-b-0;
                    }

                    .vc-container {
                        @apply z-[99];
                    }

                    .calendar-header {
                        @apply hidden;
                    }

                    .vc-pane-header-wrapper {
                        @apply top-0;
                    }

                    .vc-day,
                    .vc-day-content,
                    .vc-highlight {
                        @apply min-w-full;
                        min-height: calc(100vw / 8);
                    }
                }
            }
        }

        :deep(.vc-container) {
            @apply z-[99] flex flex-col min-w-full max-w-[100vw] md:min-w-0 rounded border border-gray-500;
        }
    }

    :deep(.vc-pane-container) {
        @apply flex flex-col-reverse;

        .calendar-header {
            @apply py-2 mx-4 bg-transparent rounded-t text-primary-500 font-semibold border-b border-gray-200 text-lg text-center;
        }

        .vc-day,
        .vc-day-content,
        .vc-highlight {
            @apply min-h-[40px] min-w-[40px] rounded-none;
        }
        .vc-highlights + .vc-day-content {
            @apply font-normal;
        }
    }

    :deep(.vc-popover-content-wrapper) {
        @apply z-[99];
    }

    :deep(.vc-popover-content) {
        @apply overflow-hidden;

        &.direction-bottom {
            @apply md:mt-1;
        }
    }

    :deep(.vc-popover-caret) {
        @apply hidden;
    }
}
:deep(.vc-primary) {
    --vc-accent-50: theme('colors.primary.50');
    --vc-accent-100: theme('colors.primary.100');
    --vc-accent-200: theme('colors.primary.200');
    --vc-accent-300: theme('colors.primary.300');
    --vc-accent-400: theme('colors.primary.400');
    --vc-accent-500: theme('colors.primary.500');
    --vc-accent-600: theme('colors.primary.600');
    --vc-accent-700: theme('colors.primary.700');
    --vc-accent-800: theme('colors.primary.800');
    --vc-accent-900: theme('colors.primary.900');
}
</style>
