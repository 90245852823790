<template>
    <div ref="target" class="input-group-container" :class="{ 'has-value': value?.length }">
        <div class="input-group" :class="{ error: validation ? validation.$error : false }">
            <label for="form-input-airport" class="input-group-title has-icon">{{ title }}</label>
            <i data-icon="true" class="input-icon-prepend" :class="prependIcon" />
            <input
                :id="`form-input-${name}`"
                :value="value"
                type="text"
                :name="name"
                :placeholder="placeholder"
                class="tooltip-handle"
                autocomplete="off"
                autocapitalize="off"
                spellcheck="false"
                @input="(event) => emit('update:value', (event.target as HTMLInputElement).value)"
            />
            <div v-if="tooltip" class="tooltip">{{ tooltip }}</div>
            <div v-if="validation && validation.$error" class="error-tooltip">
                {{ validation.required.$message }}
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
defineProps({
    name: { type: String, required: true, default: '' },
    title: { type: String, required: true, default: '' },
    tooltip: { type: String, required: false },
    placeholder: { type: String, required: true, default: 'Placeholder' },
    value: { type: String, required: false },
    prependIcon: { type: String, required: false },
    validation: { type: Object, required: false, default: null },
});

const emit = defineEmits(['update:value']);
</script>

<style lang="postcss" scoped>
.input-group {
    .input-icon-append {
        @apply hidden z-[2] !cursor-pointer !pointer-events-auto;
    }

    &:hover {
        .input-icon-append {
            @apply block;
        }
    }
}
</style>
