<template>
    <div class="dates-container single-date-container">
        <div class="input-group-container has-value">
            <div class="group-inputs">
                <div
                    v-on-click-outside="() => toggleCalendar(false)"
                    class="input-group mr-4"
                    :class="{
                        error: validation ? validation?.date?.$error || validation?.time?.$error : false,
                    }"
                    :data-tooltip="tooltip"
                >
                    <label class="input-group-title time has-icon">{{ label.date }}</label>
                    <div class="input-icon-prepend">
                        <NuxtIcon name="ri:arrow-right-line" />
                    </div>
                    <div class="input-icon-append">
                        <NuxtIcon name="ri:calendar-2-line" />
                    </div>
                    <input
                        :id="`form-input-${name}-date`"
                        :value="data.date ? formatDate(data.date, 'day, dd/mm/yyyy') : ''"
                        :name="`${name}_date`"
                        :placeholder="placeholder"
                        class="tooltip-handle single-date-datepicker-input"
                        readonly
                        @click="toggleCalendar(true)"
                    />
                    <LazySearchboxComponentsDatePickerElement
                        v-if="calendarShow"
                        :value="data.date"
                        mode="date"
                        :allow-single-range="allowSingleRange"
                        :name="name"
                        color="primary"
                        :class="anchor"
                        :min-date="new Date(minDate)"
                        :max-date="new Date(new Date().setMonth(new Date().getMonth() + 12))"
                        @toggle-calendar="toggleCalendar"
                        @update:value="onUpdateDate"
                    />
                    <div v-if="tooltip?.length" class="tooltip">{{ tooltip }}</div>
                </div>
                <div
                    class="input-group"
                    :class="{
                        error: validation ? validation?.date?.$error || validation?.time?.$error : false,
                    }"
                >
                    <label class="input-group-title time-label has-icon">{{ label.time }}</label>
                    <div class="input-icon-prepend">
                        <NuxtIcon name="ri:arrow-down-s-line" />
                    </div>
                    <div class="input-icon-append">
                        <NuxtIcon name="ri:timer-2-line" />
                    </div>
                    <select
                        :id="`form-input-${name}`"
                        v-model="timeSelected"
                        :name="name"
                        class="tooltip-handle time-select-input"
                        @change="(event) => onTimeUpdate((event.target as HTMLInputElement).value)"
                    >
                        <option
                            v-for="timeOption in timeOptions"
                            :key="timeOption.value"
                            :value="timeOption.label"
                            :selected="timeOption.label === timeSelected"
                            :disabled="timeOption.disabled"
                        >
                            {{ timeOption.value }}
                        </option>
                    </select>
                </div>
            </div>
            <div
                v-if="validation?.date?.$error && validation?.$errors?.[0]?.$message"
                class="error-tooltip"
                :class="name"
            >
                {{ validation?.$errors[0].$message }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';

interface ISelectOption {
    key: string;
    label: string;
    value: string;
    disabled: boolean;
}

const props: any = defineProps({
    name: { type: String, required: true },
    value: { type: Object, required: false, default: null },
    label: { type: Object, required: false, default: () => ({}) },
    placeholder: { type: String, required: false, default: 'Select Date' },
    datePickerTitle: { type: String, required: false, default: 'Please select a date' },
    tooltip: { type: String, required: false, default: '' },
    anchor: { type: String, required: false, default: 'left' },
    validation: { type: Object, required: false, default: null },
    minDate: { type: Date, required: false, default: null },
    allowSingleRange: { type: Boolean, required: false, default: false },
    minTime: { type: String, required: false, default: '' },
});

const data = computed(() => ({
    date: props.value.date,
    time: props.value.time,
}));

const onTimeUpdate = (value) => {
    data.value.time = value;
    emit('update:value', data.value);
};

const onUpdateDate = (value) => {
    data.value.date = value;
    emit('update:value', data.value);
};

const isDisabledOption = (timeOption: string): boolean => {
    if (!props.minTime) {
        return false;
    }

    const baseDate = `${new Date().getFullYear()}-01-01`;
    const dateTime1 = new Date(`${baseDate} ${timeOption}:00`);
    const dateTime2 = new Date(`${baseDate} ${props.minTime}:00`);

    // Compare the two date-time objects
    return dateTime1 <= dateTime2;
};

const timeSelected = ref(props.value?.time || '12:00');

const options = (() => {
    const times: ISelectOption[] = [];

    if (import.meta.client) {
        const startTime = new Date(Date.UTC(0, 0, 1, 24, 0));
        const endTime = new Date(Date.UTC(0, 0, 2, 23, 59));

        while (startTime <= endTime) {
            const time = new Date(startTime);
            const hhmm =
                time.getUTCHours().toString().padStart(2, '0') + ':' + time.getUTCMinutes().toString().padStart(2, '0');

            times.push({ label: hhmm, value: hhmm, disabled: false, key: hhmm } as ISelectOption);
            startTime.setUTCHours(startTime.getUTCHours() + 1);
        }
    }

    return times;
})();

const timeOptions = computed(() => {
    return options.map((item) => {
        item.disabled = isDisabledOption(item.value);
        item.key = `${Date.now()}-${item.value}`;

        return item;
    });
});

// Resolve Datepicker
const calendarShow: any = ref(false);

// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value']);

function toggleCalendar(val: boolean) {
    if (val) {
        calendarShow.value = true;
        document.body.classList.add('overflow-hidden', 'md:overflow-auto', 'min-h-screen');
    } else {
        setTimeout(() => (calendarShow.value = false), 200);
        document.body.classList.remove('overflow-hidden', 'md:overflow-auto', 'min-h-screen');
    }
}

watch(
    () => props.value?.time,
    (newVal) => {
        timeSelected.value = newVal;
    }
);
</script>

<style lang="postcss" scoped>
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
}

.time-label {
    left: 2.5rem !important;
}

.dates-container {
    @apply relative;

    .group-inputs {
        @apply flex;
        .input-group {
            @apply basis-1/2;
        }
    }
}
</style>
