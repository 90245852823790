<template>
    <div class="dropdown-select-item">
        <div class="dropdown-select-item-title">{{ title }}</div>
        <div class="dropdown-select-item-selectors">
            <div class="button" :class="{ disabled: value <= minValue }" @click="() => emit('onDecrease')">
                <NuxtIcon name="ri:indeterminate-circle-line" />
            </div>
            <div class="value">{{ value }}</div>
            <div class="button" :class="{ disabled: value >= maxValue }" @click="() => emit('onIncrease')">
                <NuxtIcon name="ri:add-circle-line" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
defineProps({
    title: { type: String, required: true },
    value: { type: Number, required: true },
    maxValue: { type: Number, required: false, default: 100 },
    minValue: { type: Number, required: false, default: 1 },
});

const emit = defineEmits<{
    (e: 'onDecrease'): void;
    (e: 'onIncrease'): void;
}>();
</script>

<style scoped lang="postcss"></style>
